import React, {useEffect} from 'react';
import Layout from "../components/Layout";
import Head from "../components/Head";

const TermsOfUse = () => {

    useEffect(() => {
        let navBar = document.getElementById("mainNav");
        navBar.classList.add("navbar-shrink");
    }, []);

    return (
        <Layout>
            <style dangerouslySetInnerHTML={{__html: `
                main > .container {
                    padding: 150px 15px 0;
                }
            `}} />
            <Head
				pageTitle="Terms of Use"
				title="Terms of Use"
				path="/terms-of-use"
				description=""
				imageUrl=""
				type="website"
				datePublished="04/19/2023"
                bodyAttributes={{ class: "d-flex flex-column h-100" }}
            />
            <main className="flex-shrink-1 bg-light">
                <div className="container">

                    <section>
                        <div className="text-center mb-5">
                            <h2 className="section-heading text-uppercase">Terms and Conditions</h2>
                        </div>

                        <p>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY. THEY GOVERN YOUR ACCESS TO AND USE OF THIS WEBSITE.
                            Welcome to our website. By your use of this website, you are agreeing to comply with and be bound by the
                            following terms and conditions of use, together with our Privacy Policy. Intraprise may make changes to the
                            content of this website, the terms of use and privacy policy at any time without notice to you. The revised
                            terms of use and privacy policy will be posted on this website. You are responsible for regularly reviewing
                            these terms of use and the privacy policy. Your continued use of this website following any changes will
                            constitute your acceptance of such changes.
                        </p>
                        <h3>Use of this website</h3>
                        <p>The content of this website is for your general information and use only. It is subject to change without
                            notice. Content may be used solely for the furtherance of your relationship with Intraprise, and you may not
                            copy, use, modify, distribute, transfer, download, upload, resell, or republish any of the content of this
                            website without the prior written authorization of Intraprise.
                        </p>
                        <h3>Copyrights and Other Intellectual Property</h3>
                        <p>This website contains material which is owned by or licensed to us or the respective owners of such material
                            and is protected by U.S. and international copyright laws and conventions. This material includes images,
                            pictures, logos, illustrations, designs, photographs, video clips, text, icons, graphics, case studies,
                            white papers, press releases, designs, and overall appearance of this website.
                        </p>
                        <h3>Disclaimer</h3>
                        <p>THIS WEBSITE AND ITS CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE WEBSITE AND ITS
                            CONTENTS IS AT YOUR SOLE RISK. THE WEBSITE AND ITS CONTENT ARE PROVIDED WITHOUT ANY REPRESENTATIONS,
                            ENDORSEMENTS, OR WARRANTIES OF ANY KIND WHATSOEVER, EITHER EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF
                            TITLE OR ACCURACY AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                            NON-INFRINGEMENT. ANY CONTENT OBTAINED THROUGH THE USE OF THIS WEBSITE IS SO OBTAINED AT YOUR OWN DISCRETION
                            AND RISK. INTRAPRISE WILL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT
                            RESULTS FROM THE DOWNLOAD OF ANY CONTENT.
                        </p>
                        <h3>Passwords</h3>
                        <p>You are responsible for maintaining the confidentiality of any password(s) you are given to access this
                            website, and are fully responsible for all activities that occur under your password(s). You agree to notify
                            Intraprise immediately of any unauthorized use of your password(s).
                        </p>
                        <h3>Termination of Use</h3>
                        <p>You agree that Intraprise may, in its sole discretion, at any time terminate your access to this website and
                            any account(s) you may have established in connection with this website. Access to this website may be
                            monitored by Intraprise.
                        </p>
                        <h3>Applicable Law</h3>
                        <p>All matters relating to your access to, and use of, this website will be governed by U.S. federal law or the
                            laws of the Commonwealth of Pennsylvania. Any legal action or proceeding relating to your access to, or use
                            of, this website will be instituted in a state or federal court in Bucks County, Pennsylvania. You and
                            Intraprise agree to submit to the jurisdiction of, and agree that venue is proper in, these courts in any
                            such legal action or proceeding.
                        </p>

                    </section>

                </div>
            </main>
        </Layout>
    );
}

export default TermsOfUse;
